/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useEffect, useState } from 'react'
import { useTheme } from 'react-jss'

const useResponsive = (size) => {
  const theme = useTheme()
  if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined') return false

  const mediaQuery = window.matchMedia(`(max-width: ${theme.sizes[size]}px)`)
  const [match, setMatch] = useState(!!mediaQuery.matches)

  useEffect(() => {
    const handler = () => setMatch(!!mediaQuery.matches)
    mediaQuery.addListener(handler)
    return () => mediaQuery.removeListener(handler)
  }, [])

  const [MatchQuery, SetMatchQuery] = useState(() => () => <></>)
  const [NotMatchQuery, SetNotMatchQuery] = useState(() => () => <></>)

  useEffect(() => {
    const MatchQueryComponent = () => ({ children }, props) => {
      return (
        match
          ? (
            <React.Fragment {...props}>
              {children}
            </React.Fragment>
          )
          : ''
      )
    }
    SetMatchQuery(MatchQueryComponent)
    const NotMatchQueryComponent = () => ({ children }, props) => {
      return (
        !match
          ? (
            <React.Fragment {...props}>
              {children}
            </React.Fragment>
          )
          : ''
      )
    }
    SetNotMatchQuery(NotMatchQueryComponent)
  }, [match])

  return [MatchQuery, NotMatchQuery, match]
}

export default useResponsive
