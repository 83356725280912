import {
  SET_LAYOUT_STYLE,
  SET_LAYOUT_BACK_PROP,
  RESET_LAYOUT_BACK_PROP,
  SET_LAYOUT_HEADER_MINIMIZE,
  SET_LAYOUT_HEADER_LOCKED,
  SET_LAYOUT_TOP,
  SET_LAYOUT_IS_LANDING,
  SET_LAYOUT_TRANSITION,
  SET_LAYOUT_HEADER_SUBMENU_VISIBLE,
  SET_LAYOUT_NEWS_PROP,
  SET_LAYOUT_PAGE_TITLE,
} from '@/actions/types'

const setLayoutStyle = (value) => ({
  type: SET_LAYOUT_STYLE,
  payload: value,
})

const setLayoutHeaderMinimize = (bool) => ({
  type: SET_LAYOUT_HEADER_MINIMIZE,
  payload: bool,
})

const setLayoutHeaderLocked = (bool) => ({
  type: SET_LAYOUT_HEADER_LOCKED,
  payload: bool,
})

const setLayoutSubmenuVisible = (bool) => ({
  type: SET_LAYOUT_HEADER_SUBMENU_VISIBLE,
  payload: bool,
})

const setLayoutTop = (bool) => ({
  type: SET_LAYOUT_TOP,
  payload: bool,
})

const setLayoutIsLanding = (bool) => ({
  type: SET_LAYOUT_IS_LANDING,
  payload: bool,
})

const setLayoutTransition = (bool) => ({
  type: SET_LAYOUT_TRANSITION,
  payload: bool,
})

const setBackButtonProp = (key, value) => ({
  type: SET_LAYOUT_BACK_PROP,
  payload: value,
  key,
})

const resetBackButtonProp = () => ({ type: RESET_LAYOUT_BACK_PROP })

const setLayoutNewsProp = (key, value) => ({
  type: SET_LAYOUT_NEWS_PROP,
  payload: value,
  key,
})

const setPageTitle = (value) => ({
  type: SET_LAYOUT_PAGE_TITLE,
  payload: value,
})

export {
  setLayoutStyle,
  setBackButtonProp,
  resetBackButtonProp,
  setLayoutHeaderMinimize,
  setLayoutHeaderLocked,
  setLayoutTransition,
  setLayoutTop,
  setLayoutIsLanding,
  setLayoutSubmenuVisible,
  setLayoutNewsProp,
  setPageTitle,
}
