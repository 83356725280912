import hexToRgba from 'hex-rgba'
import { fonts, fontSize } from '@/styles/typography'
import { gutter, padder, wrapper, smallWrapper, frame, wrapperFrame } from '@/styles/grid'
import colors, { categoriesColors } from '@/styles/colors'
import easings from '@/styles/easings'
import { imageFit, responsiveRect, underlined } from '@/styles/utility'
import header from '@/styles/header'
import mq, { sizes } from '@/styles/mq'
import { space } from './space'
import zindex from '@/styles/zindex'

export const theme = {
  mq,
  colors,
  categoriesColors,
  zindex,
  gutter,
  padder,
  wrapper,
  smallWrapper,
  wrapperFrame,
  frame,
  easings,
  fonts,
  underlined,
  imageFit,
  responsiveRect,
  header,
  fontSize,
  sizes,
  space,
  getRgba: (col, opacity) => hexToRgba(col, opacity * 100),
  vwToPx: (vw) => (parseInt(vw, 10) * window.innerWidth) / 100,
}
