import { memo } from 'react'
import injectSheet from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
import clsx from 'clsx'
import style from './style'

const Spinner = ({
  classes,
  className,
  isLoading,
  strokeWidth,
}) => {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { layoutStyle } = useSelector((state) => ({
    layoutStyle: state.layout.style,
  }), shallowEqual)

  return (
    <div
      className={clsx({
        [classes.spinner]: true,
        [classes.spinnerThemeInverse]: layoutStyle === 'alternative',
        [className]: className,
        [classes.visible]: isLoading,
      })}
    >
      <svg
        viewBox="0 0 50 50"
        className={classes.spinnerLoader}
      >
        <circle
          className={classes.spinnerPath}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth={strokeWidth}
        />
      </svg>
    </div>
  )
}

Spinner.defaultProps = {
  strokeWidth: 1,
}

export default injectSheet(style)(memo(Spinner))
