import { SET_CURSOR_VALUE } from '@/actions/types'

const setCursorValue = (key, value) => ({
  type: SET_CURSOR_VALUE,
  payload: value,
  key,
})

export {
  setCursorValue,
}
