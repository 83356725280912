import constants from '@/constants'

const pushToDataLayer = (data) => {
  (global.dataLayer || []).push({ event: 'GAevent', ...data })
}

export default () => {
  const {
    COOKIE_CONSENT,
  } = constants.EVENTS.DATALAYER
  const { EMITTER } = constants

  EMITTER.on(COOKIE_CONSENT, () => {
    pushToDataLayer({
      eventCategory: 'cookie',
      eventAction: 'cookie-consent',
    })
  })
}
