const style = (theme) => {
  const output = {
    logo: {
      display: 'block',
      lineHeight: 0,
      '& svg': {
        maxWidth: '100%',
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mq.sm] = {}

  return output
}
export default style
