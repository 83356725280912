import mq from '@/styles/mq'

export default {
  logo: {
    width: 46,
    height: 46,
  },
  burger: {
    wrap: 45,
    width: 15,
    height: 10,
  },
  topBar: {
    height: 40,
    [mq.xxl]: {
      height: 30,
    },
  },
  subBar: {
    height: 40,
    [mq.xxl]: {
      height: 30,
    },
  },
}
