/* Why did you render */
// import './wdyr'
/* Accessibility */
import 'focus-visible'

import ReactDOM from 'react-dom'
import { JssProvider } from 'react-jss'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor, history } from '@/base/store'
import '@/detects/modernizr'
import tracking from '@/tracking'
import AppComponent from '@/components/App'
// import registerServiceWorker from '@/pwa/serviceWorker'

tracking()

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <JssProvider id={{ minify: process.env.NODE_ENV === 'production' }}>
            <AppComponent />
          </JssProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById('root'),
)

/* Register Service Worker */
// registerServiceWorker()
