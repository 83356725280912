import { SET_BOUNDS_ELEMENT } from '@/actions/types'

const setBoundsElement = (key, bounds) => ({
  type: SET_BOUNDS_ELEMENT,
  payload: bounds,
  key,
})

export {
  setBoundsElement,
}
