import { lazy } from 'react'
import { internazionalizePath, getPathFromCpt } from '@/utils/path'

const Home = lazy(() => import(/* webpackChunkName: "home" */'@/pages/Home'))
const Editorial = lazy(() => import(/* webpackChunkName: "editoria" */'@/pages/Editorial'))
const ArchiveProducts = lazy(() => import(/* webpackChunkName: "archive-products" */'@/pages/ArchiveProducts'))
const ArchiveNews = lazy(() => import(/* webpackChunkName: "archive-products" */'@/pages/ArchiveNews'))
const Contact = lazy(() => import(/* webpackChunkName: "contact" */'@/pages/Contact'))
const Privacy = lazy(() => import(/* webpackChunkName: "contact" */'@/pages/Privacy'))
const Certifications = lazy(() => import(/* webpackChunkName: "contact" */'@/pages/Certifications'))
const Post = lazy(() => import(/* webpackChunkName: "editoria" */'@/pages/Post'))
const Landing = lazy(() => import(/* webpackChunkName: "editoria" */'@/pages/Landing'))
const NotFound = lazy(() => import(/* webpackChunkName: "not_found" */'@/pages/NotFound'))

export default (routing) => [
  {
    key: 0,
    path: internazionalizePath('/'),
    exact: true,
    render: () => <Home stub={routing.find((r) => r.cpt === 'home')?.slug} />,
  },
  {
    key: 1,
    path: internazionalizePath(
      getPathFromCpt(routing, 'contact'),
    ),
    render: () => <Contact />,
  },
  {
    key: 2,
    path: internazionalizePath(
      getPathFromCpt(routing, 'editorial'),
    ),
    render: () => <Editorial />,
  },
  {
    key: 3,
    path: internazionalizePath(
      getPathFromCpt(routing, '_cta_tipology'),
    ),
    render: () => <ArchiveProducts />,
  },
  {
    key: 4,
    path: internazionalizePath(
      getPathFromCpt(routing, 'post'),
    ),
    render: () => <Post />,
  },
  {
    key: 5,
    path: internazionalizePath(
      getPathFromCpt(routing, 'archive_news'),
    ),
    render: () => <ArchiveNews />,
  },
  {
    key: 6,
    path: internazionalizePath(
      getPathFromCpt(routing, 'certifications'),
    ),
    render: () => <Certifications />,
  },
  {
    key: 7,
    path: internazionalizePath(
      getPathFromCpt(routing, 'privacy'),
    ),
    render: () => <Privacy />,
  },
  {
    key: 8,
    path: internazionalizePath(
      getPathFromCpt(routing, 'landing'),
    ),
    render: () => <Landing />,
  },
  {
    key: 999,
    render: () => <NotFound />,
  },
]
