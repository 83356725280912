/* eslint-disable no-nested-ternary */
import { memo } from 'react'
import { useSpring, a } from 'react-spring'
import { createUseStyles } from 'react-jss'
import style from './style'

const useStyles = createUseStyles(style)

const Burger = ({
  isActive,
  isHover,
  className,
}) => {
  const classes = useStyles({ isHover, isActive })

  const springConfig = () => ({
    mass: 0.3,
    tension: 200,
  })

  const aStyle = useSpring({
    x: isActive ? '50%' : isHover ? '100%' : '0%',
    y: isActive ? 5 : 0,
    rotate: isActive ? '405deg' : '0deg',
    config: springConfig,
  })
  const bStyle = useSpring({
    rotate: isActive ? '135deg' : '0deg',
    scale: isActive ? [0.9, 1] : [1, 1],
    config: {
      ...springConfig,
      tension: 120,
    },
  })
  const cStyle = useSpring({
    x: isActive ? '50%' : isHover ? '0%' : '100%',
    y: isActive ? -5 : 0,
    opacity: 1,
    rotate: isActive ? '405deg' : '0deg',
    config: springConfig,
  })

  return (
    <div
      className={`${classes.root} ${className}`}
    >
      <div className={classes.list}>
        <a.div style={aStyle} className="a" />
        <a.div style={bStyle} className="b" />
        <a.div style={cStyle} className="c" />
      </div>
    </div>
  )
}

Burger.defaultProps = {
  height: 12,
  width: 30,
}

export default memo(Burger)
