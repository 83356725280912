import { Suspense, useEffect, useCallback } from 'react'
import { ThemeProvider } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import ScrollbarContext from '@/context/scrollbar'
import Handlers from '@/components/Handlers'
import Routes from '@/router'
import Layout from '@/components/Layout'
import * as routingActions from '@/actions/routing'
import * as optionsActions from '@/actions/options'
import * as navActions from '@/actions/nav'
import * as localeActions from '@/actions/locale'
import useViewport from '@/hooks/useViewport'
import credits from '@/utils/credits'
import { theme } from '@/styles/style'

const App = () => {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { routes, currentLanguage } = useSelector((state) => ({
    routes: state.routing.routes,
    currentLanguage: state.locale.currentLanguage,
  }), shallowEqual)

  const { vw, vh, vwfixed, vhfixed } = useViewport()

  useEffect(() => {
    document.documentElement.style.setProperty('--vw', `${vw}px`)
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.style.setProperty('--vw-fixed', `${vwfixed}px`)
    document.documentElement.style.setProperty('--vh-fixed', `${vhfixed}px`)
  }, [vw, vh, vwfixed, vhfixed])

  const dispatch = useDispatch()
  const fetchRouting = useCallback(() => dispatch(routingActions.fetchRouting()), [dispatch])
  const fetchOptions = useCallback(() => dispatch(optionsActions.fetchOptions()), [dispatch])
  const fetchMenuLocations = useCallback(() => dispatch(navActions.fetchMenuLocations()), [dispatch])
  const fetchLocales = useCallback(() => dispatch(localeActions.fetchLocales()), [dispatch])
  const setLanguage = useCallback(() => dispatch(localeActions.setLanguage()), [dispatch])

  useEffect(() => {
    credits()
    setLanguage()
  }, [])

  useEffect(() => {
    if (currentLanguage !== null) {
      fetchRouting()
      fetchLocales()
      fetchOptions()
      fetchMenuLocations()
    }
  }, [currentLanguage])

  return routes?.length > 0 && (
    <ThemeProvider theme={theme}>
      <Handlers>
        <ScrollbarContext>
          <Layout>
            <Suspense fallback={<div />}>
              <Routes routes={routes} />
            </Suspense>
          </Layout>
        </ScrollbarContext>
      </Handlers>
    </ThemeProvider>
  )
}

export default App
