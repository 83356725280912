import injectSheet from 'react-jss'
import { useState, useEffect, useRef } from 'react'
import { useSpring, a } from 'react-spring'
import { useLocation } from 'react-router-dom'
import style from './style'

const Accordion = ({
  root,
  children,
  defaultOpen,
  classes,
}) => {
  const [open, setOpen] = useState(false)
  const $content = useRef(null)

  const location = useLocation()

  useEffect(() => {
    setTimeout(() => { setOpen(false) }, 500)
  }, [location])

  const aStyle = useSpring({
    height: open ? $content.current.scrollHeight : 0,
    x: open ? 0 : 20,
    opacity: open ? 1 : 0,
  })

  useEffect(() => {
    setOpen(defaultOpen)
  }, [defaultOpen])

  const handleClick = (e) => {
    e.preventDefault()
    setOpen((o) => !o)
  }

  const Root = (typeof root === 'string') ? () => root : root

  return (
    <div className="accordion">
      <span
        onClick={(e) => handleClick(e)}
        aria-hidden="true"
        className={classes.root}
      >
        <Root />
      </span>
      <a.div className={classes.animated} style={aStyle}>
        <div ref={$content} className={classes.content}>
          {children}
        </div>
      </a.div>
    </div>
  )
}

export default injectSheet(style)(Accordion)
