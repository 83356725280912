import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { cleanOrigin } from '@/utils/path'
import * as layerActions from '@/actions/layer'

const NavLink = ({
  to,
  // title,
  className,
  tabIndex,
  //   onClick,
  //   onMouseDown,
  //   onMouseEnter,
  //   onMouseLeave,
  children,
  'data-layer': dataLayer,
}) => {
  const history = useHistory()

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])

  /*------------------------------
  Handle Click
  ------------------------------*/
  const handleClick = useCallback((e) => {
    // if (e.defaultPrevented) return
    closeMenu()
    setTimeout(() => {
      const notCmdClick = (e.metaKey === undefined && e.ctrlKey === undefined) || (!e.metaKey && !e.ctrlKey)
      if (!notCmdClick) return
      e.preventDefault()
      const link = cleanOrigin(to)
      if (history.location.pathname !== link) history.push(link)
    }, 1000)
  }, [])

  return (
    <span
      onClick={(e) => handleClick(e)}
        // to={cleanOrigin(to)}
      className={`${className} nav-link`}
      tabIndex={tabIndex}
      data-layer={dataLayer}
      role="button"
      aria-hidden={true}
    >
      {children}
    </span>
  )
}

export default NavLink
