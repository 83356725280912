import { RECEIVE_OPTIONS } from '@/actions/types'

const initialState = {
  strings: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_OPTIONS:
      state = {
        ...state,
        strings: action.payload,
      }
      break
    default:
      return state
  }
  return state
}
