const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 30,
      left: 30,
      padding: [200, 40, 80, 40],
      width: '28vw',
      background: theme.colors[1],
      zIndex: theme.zindex.mainNav,
      overflow: 'auto',
      overflowX: 'hidden',
      borderRadius: 7,
      transformOrigin: 'left top',
    },
    visible: {
      opacity: 1,
    },
    container: {
      height: '100%',
    },
    wrapper: {
      width: '100%',
      height: '100%',
    },
    lang: {
      position: 'absolute',
      right: theme.space[2],
      bottom: theme.space[2],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: `opacity 0.3s ${theme.easings.garret}`,
      color: theme.colors[0],
      // height: (props) => props.headerHeight,
      fontSize: 16,
    },
    nav: {
      '& li': {
        lineHeight: 1,
        '&:not(:last-child)': {
          marginBottom: 15,
        },
      },
      '& a': {
        position: 'relative',
        fontFamily: theme.fonts[0],
        color: theme.colors[0],
        lineHeight: 1,
      },
    },
    productNav: {
      marginTop: 60,
      '& li': {
        '&:not(:last-child)': {
          marginBottom: 25,
        },
      },
      '& button': {
        fontSize: 28,
        textTransform: 'uppercase',
        fontWeight: 700,
        color: theme.colors[0],
      },
    },
    childNav: {
      marginTop: 20,
    },
    mainNav: {
      fontSize: 'calc(18px + 0.3vw)',
      '& span.contactLink, & span.nav-link, & a': {
        cursor: 'pointer',
        display: 'block',
        userSelect: 'none',
        paddingLeft: '60px',
        position: 'relative',
        '& span.index': {
          transition: 'transform 0.4s ease 0s',
          letterSpacing: 1,
          position: 'absolute',
          fontSize: '0.6em',
          color: theme.colors[4],
          fontWeight: 600,
          left: 0,
          bottom: 0,

        },
        '&:hover': {
          color: theme.colors[2],
        },

      },
    },
    mainActive: {
      pointerEvents: 'none',
      cursor: 'default',
      '& span.index': {
        '&:before': {
          content: '""',
          position: 'absolute',
          left: '0px',
          bottom: '-2px',
          width: '100%',
          height: '1px',
          backgroundColor: theme.colors[4],
        },
      },
    },
    active: {
      pointerEvents: 'none',
      cursor: 'default',
      opacity: 0.5,
    },
  }

  /*------------------------------
  XXL
  ------------------------------*/
  output[theme.mq.xxl] = {
    root: {
      width: '36vw',
    },
  }

  /*------------------------------
  LG
  ------------------------------*/
  output[theme.mq.lg] = {
    root: {
      width: '48vw',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mq.sm] = {
    root: {
      width: '100vw',
      height: 'auto',
      paddingTop: 100,
      top: 0,
      left: 0,
      borderRadius: 0,
      // zIndex: theme.zindex.burger + 1,
    },
    container: {
      height: 'auto',
    },
    wrapper: {
      '& > *': {
        flexBasis: '100%',
      },
    },
    // nav: {
    //   '& li': {
    //     textAlign: 'center',
    //   },
    // },
    mainNav: {
      marginTop: 50,
      fontSize: 20,
      '& a': {
        textTransform: 'none',
      },
    },
    extraNav: {
      fontSize: 20,
      marginTop: 15,
      '& li': {
        '&:not(:last-child)': {
          marginBottom: 15,
        },
      },
    },
    infoNav: {
      fontSize: 20,
      marginTop: 50,
      '& ul': {
        flexDirection: 'column',
      },
      '& li': {
        '&:not(:last-child)': {
          marginRight: 0,
        },
      },
    },
  }

  return output
}

export default style
