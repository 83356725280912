import mq from '@/styles/mq'

export const gutter = {
  lg: 6,
  sm: 4,
}

export const padder = {
  lg: '8.2vw',
  sm: '30px',
}

export const wrapper = {
  paddingLeft: '4.1vw',
  paddingRight: '4.1vw',
  [mq.sm]: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}

export const smallWrapper = {
  paddingLeft: '8.2vw',
  paddingRight: '8.2vw',
  [mq.sm]: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}

export const frame = {
  paddingLeft: gutter.lg,
  paddingRight: gutter.lg,
  [mq.sm]: {
    paddingLeft: gutter.sm,
    paddingRight: gutter.sm,
  },
}

export const wrapperFrame = {
  paddingLeft: `calc(${padder.lg} + ${gutter.lg}px)`,
  paddingRight: `calc(${padder.lg} + ${gutter.lg}px)`,
  [mq.sm]: {
    paddingLeft: `calc(${padder.sm}px + ${gutter.sm}px)`,
    paddingRight: `calc(${padder.sm}px + ${gutter.sm}px)`,
  },
}
