import { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import DelayLink from '@/components/DelayLink'
import constants from '@/constants'
import style from './style'

const useStyles = createUseStyles(style)

const CookieBanner = ({
  text,
  linkLabel,
  acceptLabel,
  link,
  days: daysFromProps,
  name: nameFromProps,
}) => {
  const classes = useStyles()
  const $root = useRef(null)
  const [visible, setVisible] = useState(false)
  const [trackingEnabled, setTrackingEnabled] = useState(false)

  const { isReady, isFirstLoad } = useSelector((state) => ({
    isFirstLoad: state.loading.isFirstLoad,
    isReady: state.loading.isReady,
  }), shallowEqual)

  /*------------------------------
  Set Cookie
  ------------------------------*/
  const setCookie = (name, value, days) => {
    let expires = ''
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      expires = `; expires=${date.toUTCString()}`
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`
  }

  /*------------------------------
  Get Cookie
  ------------------------------*/
  const getCookie = (name) => {
    const nameEQ = `${name}=`
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  /*------------------------------
  Dismiss Cookie
  ------------------------------*/
  const dismissCookie = () => {
    setTrackingEnabled(true)
    setCookie(nameFromProps, '1', daysFromProps)
    gsap.to($root.current, 0.7, {
      y: '100%',
      opacity: 0,
      ease: 'power3.inOut',
    })
  }

  /*------------------------------
  Enable Tracking GTM
  ------------------------------*/
  useEffect(() => {
    if (getCookie(nameFromProps)) constants.EMITTER.emit(constants.EVENTS.DATALAYER.COOKIE_CONSENT)
  }, [trackingEnabled])

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    if (getCookie(nameFromProps)) setVisible(false)
    if (getCookie(nameFromProps) === null) setVisible(true)
  }, [])

  /*------------------------------
  Visible
  ------------------------------*/
  useEffect(() => {
    if (visible) {
      gsap.set($root.current, { y: '100%' })
    }
  }, [visible])

  /*------------------------------
  First Load
  ------------------------------*/
  useLayoutEffect(() => {
    if (isFirstLoad && isReady) {
      gsap.to($root.current, 1, {
        y: '0%',
        opacity: 1,
        delay: 3,
        ease: 'power3.inOut',
      })
    }
  }, [isFirstLoad, isReady])

  /*------------------------------
  Render
  ------------------------------*/
  return visible && (
    <div
      className={classes.root}
      ref={$root}
    >
      <div className={classes.banner}>
        <div className={classes.content}>
          <div className={classes.message}>
            {text}
            <DelayLink
              to={link}
            >
              {linkLabel}
            </DelayLink>
          </div>
          <button
            className={classes.close}
            onClick={dismissCookie}
            aria-label="dismiss-cookie"
          >
            {acceptLabel}
          </button>
        </div>
      </div>
    </div>
  )
}

CookieBanner.defaultProps = {
  days: 90,
  name: 'user-has-accepted-cookies',
}

export default CookieBanner
