export default [
  '#191921', // #0 Dark
  '#ffffff', // #1 Light
  '#604BB7', // #2 Purple
  '#61BED3', // #3 Cyan
  '#707070', // #4 Gray
  '#52a1b3', // #5 Dark Cyan
]

export const categoriesColors = [
  '#aa9a8b', // Hospitality
  '#90a2b2', // Automotive
  '#83908a', // Adventure
]
