const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      zIndex: theme.zindex.cookiebar,
      paddingBottom: '1.5vh',
      extend: theme.smallWrapper,
    },
    banner: {
      background: 'black',
      padding: '4px 11px 4px 15px',
      borderRadius: 28,
      maxWidth: 600,
    },
    content: {
      position: 'relative',
      zIndex: 1,
      fontSize: 14,
      padding: '26px 10px',
      paddingRight: 85,
      lineHeight: '1.1',
    },
    message: {
      lineHeight: 1.3,
      color: theme.colors[4],
      display: 'inline-block',
      '& a': {
        textDecoration: 'underline',
        color: theme.colors[1],
        marginTop: 5,
        display: 'block',
      },
      '& p': {
        margin: 0,
      },
    },
    close: {
      position: 'absolute',
      zIndex: 1,
      top: 22,
      right: 10,
      background: theme.colors[1],
      color: theme.colors[0],
      fontSize: '11px',
      textTransform: 'uppercase',
      width: 'auto',
      height: 'auto',
      padding: '5px 10px',
      borderRadius: '11px',
      fontWeight: 'bold',
      letterSpacing: '0.1em',
      margin: '5px 0px 4px 10px',
      cursor: 'pointer',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mq.sm] = {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      textAlign: 'center',
    },
    banner: {
      paddingLeft: 14,
      paddingRight: 14,
      borderRadius: '20px',
      margin: [0, 10, 0, 10],
      background: 'rgba(0, 0, 0, .9)',
    },
    content: {
      padding: '8px 10px',
      paddingLeft: 0,
      paddingRight: 0,
    },
    message: {
      display: 'block',
      color: theme.getRgba(theme.colors[1], 0.8),
    },
    close: {
      position: 'static',
      margin: '10px auto 0',
    },
  }

  return output
}

export default style
