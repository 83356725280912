/* eslint-disable no-nested-ternary */
import { memo, useEffect, useRef, useCallback, useState } from 'react'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useContext } from 'use-context-selector'
import gsap from 'gsap'
import Logo from '@/components/__ui/Logo'
import Burger from './Burger'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import { useRaf } from '@/components/Handlers'
import * as layerActions from '@/actions/layer'
import * as layoutActions from '@/actions/layout'
import * as boundsActions from '@/actions/bounds'
import style from './style'
import { CURRENT_LANG } from '@/constants'
import useResponsive from '@/hooks/useResponsive'
import NavLink from '@/components/Nav/NavLink'

const useStyles = createUseStyles(style)

const Header = () => {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isMenuOpen, strings, layoutStyle, productMenu, headerHeight, pageTitle } = useSelector((state) => ({
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
    strings: state.options.strings,
    languages: state.locale.languages,
    layoutStyle: state.layout.style,
    isHeaderMinimize: state.layout.header.minimize,
    headerHeight: state.bounds?.header?.height || 0,
    pageTitle: state.layout.pageTitle,
  }), shallowEqual)

  const sctx = useContext(ScrollbarContext)

  const { scroll } = sctx

  const classes = useStyles({
    layoutStyle,
    headerHeight,
  })
  const [isBurgerButtonHover, setBurgerButtonHover] = useState(false)
  const [isTop, setTop] = useState(true)
  const [headerTitle, setHeaderTitle] = useState('')
  const $root = useRef()
  const $header = useRef()
  const $headerWrapper = useRef()
  const $headerTitle = useRef()
  const $bg = useRef()

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const openMenu = useCallback(() => dispatch(layerActions.openMenu()), [dispatch])
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])
  const setBounds = useCallback((el, bounds) => dispatch(boundsActions.setBoundsElement(el, bounds)), [dispatch])
  const setLayoutTop = useCallback((bool) => dispatch(layoutActions.setLayoutTop(bool)), [dispatch])

  /*------------------------------
  Handle Mouse Move
  ------------------------------*/
  const handleMouseMove = (e) => {
    if (e.type === 'mouseenter') setBurgerButtonHover(true)
    if (e.type === 'mouseleave') setBurgerButtonHover(false)
  }

  /*------------------------------
  Calculate Header Bounds
  ------------------------------*/
  const handleResize = () => {
    if ($header.current) setBounds('header', $header.current.getBoundingClientRect())
  }

  /*------------------------------
  Handle Main Menu Click
  ------------------------------*/
  const handleMainMenuClick = useCallback(() => {
    if (isMenuOpen) closeMenu()
    if (!isMenuOpen) openMenu()
  }, [isMenuOpen])

  /*------------------------------
  Prevent Press Spacebar
  ------------------------------*/
  const preventPressSpacebar = useCallback((node) => {
    if (node !== null) {
      node.addEventListener('keyup', (e) => {
        if (e.keyCode === 32 && e.code === 'Space') e.preventDefault()
      })
    }
  }, [])

  // /*------------------------------
  // Update
  // ------------------------------*/
  const updateIsTop = useCallback(() => {
    if (scroll.current.y <= window.innerHeight * 0.4) setTop(true)
    if (scroll.current.y > window.innerHeight * 0.4) setTop(false)
  }, [])

  const [, , lg] = useResponsive('lg')
  const [, , md] = useResponsive('md')
  const [, , sm] = useResponsive('sm')

  const minimimizeHeader = () => {
    gsap.to($headerWrapper.current, {
      paddingTop: ((sm || !isTop) && !isMenuOpen) ? '30px' : (md ? '40px' : '60px'),
      paddingBottom: ((sm || !isTop) && !isMenuOpen) ? '30px' : (md ? '40px' : '60px'),
      onUpdate: () => {
        gsap.set($bg.current, { height: $headerWrapper.current?.scrollHeight })
        handleResize()
      },
      onComplete: () => {
      },
    })
  }

  useEffect(() => {
    minimimizeHeader()
  }, [lg, sm, md])

  /*------------------------------
  Set isTop on Redux Store
  ------------------------------*/
  useEffect(() => {
    setLayoutTop(isTop)
    minimimizeHeader()
    gsap.to($bg.current, {
      opacity: isTop && !isMenuOpen ? 0 : 1,
      duration: 0.6,
    })
  }, [isTop])

  useEffect(() => {
    gsap.to($bg.current, {
      opacity: isTop && !isMenuOpen ? 0 : 1,
      duration: 0.6,
    })
    // if (location.pathname === saveLocation) {
    //    minimimizeHeader(false)
    // }
    if (isMenuOpen) {
      minimimizeHeader()
    }
  }, [isMenuOpen])

  /*------------------------------
  Request Animation Frame
  ------------------------------*/
  useRaf(() => {
    updateIsTop()
  }, [isTop])

  /*------------------------------
  Render Burger
  ------------------------------*/
  const renderBurgerButton = useCallback(() => {
    return (
      <button
        className={clsx({
          [classes.burgerButton]: true,
        })}
        onClick={handleMainMenuClick}
        aria-label="toggle-main-menu"
        ref={preventPressSpacebar}
        onMouseEnter={(e) => handleMouseMove(e)}
        onMouseLeave={(e) => handleMouseMove(e)}
      >
        <Burger
          className={clsx({
            [classes.burgerIcon]: true,
            [classes.burgerIconNavOpen]: isMenuOpen,
          })}
          isActive={isMenuOpen}
          isHover={isBurgerButtonHover}
        />
      </button>
    )
  }, [strings, isMenuOpen, isBurgerButtonHover])

  /*------------------------------
  Set header title
  ------------------------------*/
  useEffect(() => {
    gsap.to($headerTitle.current, {
      autoAlpha: 0,
      duration: 0.5,
      onComplete: () => {
        setHeaderTitle(pageTitle)
        gsap.to($headerTitle.current, {
          autoAlpha: 1,
          duration: 0.5,
          delay: 0.3,
        })
      },
    })
  }, [pageTitle])

  /*------------------------------
  Render Main Bar
  ------------------------------*/
  const renderMainBar = useCallback(() => {
    return (
      <div className={classes.wrapper} ref={$headerWrapper}>
        <div
          className={classes.pageTitle}
          ref={$headerTitle}
        >
          {headerTitle}
        </div>
        <div className={classes.wrapperBurgerTitle}>
          <div className={classes.burger}>
            {renderBurgerButton()}
          </div>
          <NavLink
            to={CURRENT_LANG}
            className={clsx({
              [classes.title]: true,
              [classes.titleNavOpen]: isMenuOpen,
            })}
          >
            Sterling
            <br />
            Aromi
          </NavLink>
        </div>
        <Logo
          className={clsx({
            [classes.logo]: true,
            [classes.hideMobile]: isMenuOpen,
          })}
        />
      </div>
    )
  }, [isMenuOpen, isBurgerButtonHover, productMenu, headerTitle])

  return (
    <>
      <div className={classes.bgHeader} ref={$bg} />
      <header
        className={clsx({
          [classes.root]: true,
          [classes.rootIsMenuOpen]: isMenuOpen,
        })}
        ref={$root}
      >
        <div ref={$header}>
          {renderMainBar()}
        </div>
      </header>
    </>
  )
}

Header.defaultProps = {
  menuLabel: 'Menu',
}

export default memo(Header)
