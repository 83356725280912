const style = {
  root: {
    cursor: 'pointer',

  },
  animated: {
    overflow: 'hidden',
  },
  content: {
    paddingTop: '20px',
    paddingLeft: '60px',
    '& > *': {
      marginBottom: '10px',
    },
  },
}

export default style
