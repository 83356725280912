const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zindex.loader,
      background: theme.colors[0],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    hide: {
      pointerEvents: 'none',
    },
    logoWrapper: {
      transform: 'translateY(-60%)',
      '& svg *': {
        fill: '#fff',
      },
    },
    progressContainer: {
      width: 100,
      height: 1,
      position: 'absolute',
      zIndex: 1,
      top: 'calc(50% + 30px)',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      animation: '$fadeIn 0.5s ease-in-out',
    },
    progressBackdrop: {
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: theme.getRgba(theme.colors[4], 0.2),
    },
    progressThumb: {
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      transformOrigin: '0 0',
      transform: 'scaleX(0)',
      backgroundColor: theme.colors[1],
    },
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
  }
  return output
}

export default style
