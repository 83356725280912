// routing
export const REQUEST_ROUTING = 'REQUEST_ROUTING'
export const RECEIVE_ROUTING = 'RECEIVE_ROUTING'

// regions
export const REQUEST_REGIONS = 'REQUEST_REGIONS'
export const RECEIVE_REGIONS = 'RECEIVE_REGIONS'

// options
export const REQUEST_OPTIONS = 'REQUEST_OPTIONS'
export const RECEIVE_OPTIONS = 'RECEIVE_OPTIONS'

// loading
export const SET_LOADING_VALUE = 'SET_LOADING_VALUE'

// cursor
export const SET_CURSOR_VALUE = 'SET_CURSOR_VALUE'

// layout
export const SET_LAYOUT_STYLE = 'SET_LAYOUT_STYLE'
export const SET_LAYOUT_BACK_PROP = 'SET_LAYOUT_BACK_PROP'
export const RESET_LAYOUT_BACK_PROP = 'RESET_LAYOUT_BACK_PROP'
export const SET_LAYOUT_HEADER_MINIMIZE = 'SET_LAYOUT_HEADER_MINIMIZE'
export const SET_LAYOUT_HEADER_LOCKED = 'SET_LAYOUT_HEADER_LOCKED'
export const SET_LAYOUT_HEADER_SUBMENU_VISIBLE = 'SET_LAYOUT_HEADER_SUBMENU_VISIBLE'
export const SET_LAYOUT_TOP = 'SET_LAYOUT_TOP'
export const SET_LAYOUT_IS_LANDING = 'SET_LAYOUT_IS_LANDING'
export const SET_LAYOUT_TRANSITION = 'SET_LAYOUT_TRANSITION'
export const SET_LAYOUT_NEWS_PROP = 'SET_LAYOUT_NEWS_PROP'
export const SET_LAYOUT_PAGE_TITLE = 'SET_LAYOUT_PAGE_TITLE'

// bounds
export const SET_BOUNDS_ELEMENT = 'SET_BOUNDS_ELEMENT'

// form
export const REQUEST_FORM = 'REQUEST_FORM'
export const RECEIVE_FORM = 'RECEIVE_FORM'
export const SEND_CONTACT_FORM = 'SEND_CONTACT_FORM'
export const SENDED_CONTACT_FORM = 'SENDED_CONTACT_FORM'

// nav
export const REQUEST_NAV = 'REQUEST_NAV'
export const RECEIVE_NAV = 'RECEIVE_NAV'
export const REQUEST_MENU_LOCATION = 'REQUEST_MENU_LOCATION'

// layer
export const CREATE_LAYER = 'CREATE_LAYER'
export const OPEN_LAYER = 'OPEN_LAYER'
export const CLOSE_LAYER = 'CLOSE_LAYER'
export const CLOSE_ALL_LAYER = 'CLOSE_ALL_LAYER'

// content
export const REQUEST_PAGE = 'REQUEST_PAGE'
export const RECEIVE_PAGE = 'RECEIVE_PAGE'

export const REQUEST_POSTS = 'REQUEST_POSTS'
export const RECEIVE_POSTS = 'RECEIVE_POSTS'
export const REQUEST_POST = 'REQUEST_POST'
export const RECEIVE_POST = 'RECEIVE_POST'
export const RESET_CURRENT_POST = 'RESET_CURRENT_POST'
export const REQUEST_TAXONOMY = 'REQUEST_TAXONOMY'
export const RECEIVE_TAXONOMY = 'RECEIVE_TAXONOMY'
export const REQUEST_CATEGORY = 'REQUEST_CATEGORY'
export const RECEIVE_CATEGORY = 'RECEIVE_CATEGORY'

export const REQUEST_CPTS = 'REQUEST_CPTS'
export const RECEIVE_CPTS = 'RECEIVE_CPTS'
export const REQUEST_CPT = 'REQUEST_CPT'
export const RECEIVE_CPT = 'RECEIVE_CPT'
export const RESET_CURRENT_CPT = 'RESET_CURRENT_CPT'
export const REQUEST_WC_PRODUCT = 'REQUEST_WC_PRODUCT'
export const RECEIVE_WC_PRODUCT = 'RECEIVE_WC_PRODUCT'
export const REQUEST_WC_PRODUCTS_BY_CATEGORY = 'REQUEST_WC_PRODUCTS_BY_CATEGORY'

// catalog
export const REQUEST_PRODUCT_LIST = 'REQUEST_PRODUCT_LIST'
export const RECEIVE_PRODUCT_LIST = 'RECEIVE_PRODUCT_LIST'
export const REQUEST_FILTERS = 'REQUEST_FILTERS'
export const RECEIVE_FILTERS = 'RECEIVE_FILTERS'
export const FREEZE_FILTERS = 'FREEZE_FILTERS'
export const REQUEST_FILTERED_PRODUCT_LIST = 'REQUEST_FILTERED_PRODUCT_LIST'
export const RECEIVE_FILTERED_PRODUCT_LIST = 'RECEIVE_FILTERED_PRODUCT_LIST'
export const RESET_PRODUCTS = 'RESET_PRODUCTS'

// locale
export const REQUEST_LOCALES = 'REQUEST_LOCALES'
export const RECEIVE_LOCALES = 'RECEIVE_LOCALES'
export const SET_LANGUAGE = 'SET_LANGUAGE'

// Search
export const REQUEST_SEARCH = 'REQUEST_SEARCH'
export const RECEIVE_SEARCH = 'RECEIVE_SEARCH'
export const RESET_SEARCH = 'RESET_SEARCH'
