import easings from '@/styles/easings'

export const imageFit = (fit = 'cover') => ({
  position: 'absolute',
  zIndex: 1,
  width: '100%',
  height: '100%',
  bottom: 0,
  right: 0,
  top: 0,
  left: 0,
  objectFit: `${fit}`,
  objectPosition: 'center center',
  fontFamily: `'object-fit: ${fit}; object-position: center center;'`,
  userSelect: 'none',
  userDrag: 'none',
})

export const responsiveRect = (width, height) => ({
  content: '""',
  width: '100%',
  display: 'block',
  paddingBottom: `${(height / width) * 100}%`,
})

export const underlined = (inverse = false) => ({
  position: 'relative',
  zIndex: 1,
  display: 'inline-block',
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    bottom: 1,
    width: '100%',
    height: 1,
    backgroundColor: 'currentColor',
    transition: `transform .6s ${easings['power3.out']}`,
    transform: inverse ? 'scaleX(0)' : 'scaleX(1)',
    transformOrigin: '100% 0',
  },
  '@media (hover: hover)': {
    '&:hover': {
      '&:before': {
        transform: inverse ? 'scaleX(1)' : 'scaleX(0)',
        transformOrigin: '0 0',
      },
    },
  },
})
