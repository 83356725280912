import Modernizr from '@/vendors/modernizr'

const global = (theme) => {
  const output = {
    root: {},
    '@global': {
      body: {
        fontFamily: theme.fonts[0],
        fontSize: 18,
        lineHeight: 1.333,
        color: theme.colors[0],
        background: theme.colors[0],
        overflow: 'hidden',
        '& a': {
          textDecoration: 'none',
          color: 'inherit',
          transition: 'color .3s ease-in-out',
        },
        '& button': {
          fontSize: 16,
          fontFamily: theme.fonts[0],
          padding: 0,
        },
        '& input': {
          fontSize: 16,
          fontFamily: theme.fonts[0],
          padding: 0,
        },
        '& h1, & h2, & h3, & h4': {
          fontWeight: 'normal',
          margin: 0,
          padding: 0,
        },
        '& .smooth-scroll': {
          overflowY: 'auto !important',
          height: Modernizr.nativescroll ? 'var(--vh-fixed)' : 'auto',
        },
      },
      '::selection': {
        background: theme.colors[3],
        color: theme.colors[0],
      },
      '::-moz-selection': {
        background: theme.colors[3],
        color: theme.colors[0],
      },
    },
  }

  return output
}

export default global
