import { mainCacheAxios as axios } from '@/base/axios'
import {
  REQUEST_NAV,
  RECEIVE_NAV,
  REQUEST_MENU_LOCATION,
} from '@/actions/types'
import { API } from '@/constants'

const requestNav = () => ({ type: REQUEST_NAV })

const receiveNav = (data, name) => ({
  type: RECEIVE_NAV,
  payload: data,
  key: name,
})

const fetchMenu = (name) => async (dispatch, getState) => {
  dispatch(requestNav())
  const response = await axios.get(`${API.MENU}/${name}?lang=${getState().locale.currentLanguage}`)
  dispatch(receiveNav(response.data, name))
}

// const fetchNav = (key, url) => async (dispatch, getState) => {
//   dispatch(requestNav())
//   const response = await axios.get(`${url}?lang=${getState().locale.currentLanguage}`)
//   dispatch(receiveNav(response.data, key))
// }

const requestMenuLocation = () => ({ type: REQUEST_MENU_LOCATION })

const fetchMenuLocations = () => async (dispatch, getState) => {
  dispatch(requestMenuLocation())
  const response = await axios.get(`${API.MENU_LOCATIONS}?lang=${getState().locale.currentLanguage}`)
  if (Object.values(response.data).length > 0) {
    Object.values(response.data).map((nav) => (
      dispatch(receiveNav(nav.menu, nav.location))
    ))
  }
}

export {
  fetchMenuLocations,
  fetchMenu,
}
