export const fonts = [
  '"AcuminPro", Helvetica, Arial, sans-serif', // [0]
  '"CutiveMono", Helvetica, Arial, sans-serif', // [1]
]

export const fontSize = {
  0: '14px',
  1: '20px',
  2: '28px',
  3: '48px',
  mobile: {
    0: '14px',
    1: '20px',
    2: '28px',
    3: '32px',
  },
}
