import { RECEIVE_FORM } from '@/actions/types'

const initialState = {
  login: {
    fields: [
      {
        label: 'Username',
        name: 'username',
        placeholder: 'Username',
        required: true,
        type: 'text',
      },
      {
        label: 'Password',
        name: 'password',
        placeholder: 'Password',
        required: true,
        type: 'password',
      },
    ],
    forgotPassword: true,
  },
  'forgot-password': {
    fields: [
      {
        label: 'Email Address',
        name: 'email',
        placeholder: 'Email Address',
        required: true,
        type: 'email',
      },
    ],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_FORM:
      state = {
        ...state,
        [action.key]: {
          fields: action.payload.form,
          title: action.payload.title,
        },
      }
      break
    default:
      return state
  }
  return state
}
