import { memo, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { cleanOrigin, isInternal } from '@/utils/path'

const DelayLink = ({
  to,
  title,
  className,
  tabIndex,
  onClick,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  children,
  external,
  'data-layer': dataLayer,
}) => {
  const history = useHistory()

  /*------------------------------
  Handle Click
  ------------------------------*/
  const handleClick = useCallback((e) => {
    if (e.defaultPrevented) return
    const notCmdClick = (e.metaKey === undefined && e.ctrlKey === undefined) || (!e.metaKey && !e.ctrlKey)
    if (!notCmdClick) return
    e.preventDefault()
    const link = cleanOrigin(to)

    if (history.location.pathname !== link) history.push(link)
  }, [to])

  return (
    <Choose>
      <When condition={isInternal(to) && !external}>
        <Link
          onClick={handleClick}
          to={cleanOrigin(to)}
          className={`${className} internal`}
          tabIndex={tabIndex}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          data-layer={dataLayer}
        >
          {children}
        </Link>
      </When>
      <Otherwise>
        <a
          href={to}
          target="_blank"
          rel="noopener"
          title={title}
          className={`${className} external`}
          tabIndex={tabIndex}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          data-layer={dataLayer}
        >
          {children}
        </a>
      </Otherwise>
    </Choose>
  )
}

export default memo(DelayLink)
