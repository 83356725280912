import { useRef, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { cleanOrigin } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const LangSwitcher = () => {
  const classes = useStyles()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { currentLanguage, languages } = useSelector((state) => ({
    currentLanguage: state.locale.currentLanguage,
    languages: state.locale.languages,
  }), shallowEqual)

  const [open, setOpen] = useState(false)
  const $root = useRef()

  /*------------------------------
  Click Outside
  ------------------------------*/
  useOnClickOutside($root, () => {
    setOpen(false)
  })

  /*------------------------------
  Open/Close
  ------------------------------*/
  useEffect(() => {
    const toggle = open ? 'add' : 'remove'
    $root.current.classList[toggle]('open')
  }, [open])

  /*------------------------------
  Render
  ------------------------------*/
  return (
    <div
      ref={$root}
      className={classes.root}
    >
      <ul>
        {
            Object.values(languages).map((lang, i) => {
              return (
                <li key={i.toString()} className={lang.language_code === currentLanguage ? 'active' : ''}>
                  <a
                    className={classes.option}
                    key={lang.id}
                    href={cleanOrigin(lang.url)}
                  >
                    {lang.language_code}
                  </a>
                </li>
              )
            })
        }
      </ul>
    </div>
  )
}

export default LangSwitcher
