import {
  RECEIVE_PAGE,
  RECEIVE_POSTS,
  RECEIVE_POST,
  RECEIVE_CPTS,
  RECEIVE_CPT,
  RECEIVE_TAXONOMY,
  RECEIVE_WC_PRODUCT,
} from '@/actions/types'

const initialState = {
  pages: {},
  posts: {
    items: [],
    total: 0,
    pages: 1,
  },
  currentPageName: '',
  cpt: {},
  taxonomies: {},
  products: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PAGE:
      state = {
        ...state,
        pages: {
          ...state.pages,
          [action.key]: {
            ...action.payload,
            child: action.child || [],
          },
        },
        currentPageName: action.key,
      }
      break
    case RECEIVE_CPT:
      state = {
        ...state,
        cpt: {
          [action.key]: [
            ...(state.cpt[action.key] || []),
            ...action.payload,
          ],
        },
      }
      break
    case RECEIVE_CPTS:
      state = {
        ...state,
        cpt: {
          ...state.cpt,
          [action.key]: action.payload,
        },
      }
      break
    case RECEIVE_POSTS:
      state = {
        ...state,
        posts: {
          items: action.payload,
          total: action.totalPosts,
          pages: action.totalPages,
        },
      }
      break
    case RECEIVE_TAXONOMY:
      state = {
        ...state,
        taxonomies: {
          ...state.taxonomies,
          [action.key]: action.payload,
        },
      }
      break
    case RECEIVE_POST:
      state = {
        ...state,
        posts: {
          ...state.posts,
          items: action.payload,
        },
      }
      break
    case RECEIVE_WC_PRODUCT:
      state = {
        ...state,
        products: [
          ...state.products,
          ...action.payload,
        ],
      }
      break
    default:
      return state
  }
  return state
}
