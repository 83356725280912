/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import { memo, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import DelayLink from '@/components/DelayLink'
import LangSwitcher from '@/components/__ui/LangSwitcher'
import usePrevious from '@/hooks/usePrevious'
import useMediaQuery from '@/hooks/useMediaQuery'
import { getSlug } from '@/utils/path'
import { decodeEntities } from '@/utils/decodeEntities'
import * as layerActions from '@/actions/layer'
import Accordion from '@/components/__commons/Accordion'
import style from './style'
import NavLink from './NavLink'

const useStyles = createUseStyles(style)

const Nav = () => {
  const $root = useRef()
  const $wrapper = useRef()
  const location = useLocation()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { languages, isMenuOpen, mainMenu, headerHeight, headerY } = useSelector((state) => ({
    // pathname: state.router.location.pathname,
    languages: state.locale.languages,
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
    extraMenu: state.nav.extraMenu || [],
    productMenu: state.nav.productMenu || [],
    mainMenu: state.nav.mainMenu || [],
    footerMainMenu: state.nav.footerMainMenu || [],
    strings: state.options.strings,
    headerHeight: state.bounds?.header?.height || 0,
    headerY: state.bounds?.header?.top || 0,
  }), shallowEqual)

  const { match: isMobile } = useMediaQuery('sm')
  const classes = useStyles({ headerHeight, headerY, isMenuOpen })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])

  /*------------------------------
  Initialize
  ------------------------------*/
  const init = useCallback(() => {
    gsap.set($root.current, {
      autoAlpha: 0,
      y: 20,
    })
    gsap.set($wrapper.current, { autoAlpha: 0 })
  }, [])

  /*------------------------------
  Handle Esc Key Down
  ------------------------------*/
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isMenuOpen) closeMenu()
  }, [isMenuOpen])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isMenuOpen])

  /*------------------------------
  Open Main Menu
  ------------------------------*/
  const openMainMenu = useCallback(() => {
    if (isMobile) $root.current.scrollTop = 0
    gsap.killTweensOf([$root.current, $wrapper.current])
    const tl = gsap.timeline({ ease: 'power3.out' })
    tl
      .set($root.current, {
        scale: 0.7,
        skewY: '4deg',
        y: 20,
      })
      .to($root.current, {
        duration: 0.5,
        autoAlpha: 1,
        scale: 1,
        skewY: '0deg',
        y: 0,
      })
      .to($wrapper.current, {
        duration: 0.5,
        autoAlpha: 1,
        stagger: 0.08,
      }, '<0.2')
  }, [isMobile])

  /*------------------------------
  Close Main Menu
  ------------------------------*/
  const closeMainMenu = useCallback(() => {
    gsap.killTweensOf([$root.current, $wrapper.current])
    gsap.to($root.current, {
      duration: 0.4,
      autoAlpha: 0,
      scale: 0.7,
      skewY: '4deg',
      y: 20,
      ease: 'power3.out',
    })
    gsap.to($wrapper.current, {
      duration: 0.5,
      autoAlpha: 0,
    })
  }, [isMobile])

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    init()
  }, [])

  /*------------------------------
  Close Nav when isMenuOpen changed
  ------------------------------*/
  const prevIsOpen = usePrevious(isMenuOpen)
  useEffect(() => {
    if (prevIsOpen !== undefined && isMenuOpen) openMainMenu()
    if (prevIsOpen !== undefined && !isMenuOpen) closeMainMenu()
  }, [isMenuOpen])

  /*------------------------------
  Close Nav on change page
  ------------------------------*/
  // useLayoutEffect(() => {
  //   if (prevPathname !== pathname) {
  //     setTimeout(() => {
  //       if (isMenuOpen) closeMenu()
  //     }, 500)
  //   }
  // }, [pathname])

  /*------------------------------
  Mobile Language Switcher
  ------------------------------*/
  const renderLangSwitcher = useCallback(() => {
    return languages && Object.keys(languages).length > 1 && (
      <div className={classes.lang}>
        <LangSwitcher />
      </div>
    )
  }, [languages])

  /*------------------------------
  Render Main Nav
  ------------------------------*/
  const renderMainNav = useCallback(() => {
    return (
      <nav className={`${classes.nav} ${classes.mainNav}`}>
        <ul>
          {mainMenu.map((item, index) => {
            return (
              <li
                className={item.classes && item.classes.join(' ')}
                key={item.ID}
              >
                <If condition={item.children.length}>
                  <>
                    <Accordion
                      root={() => (
                        <DelayLink
                          className={clsx({
                            [classes.mainActive]: getSlug(item.url) === getSlug(location.pathname),
                          })}
                         // to={item.url}
                        >
                          <span className="index">{`0${index + 1}`}</span>
                          {decodeEntities(item.title)}
                        </DelayLink>
                      )}
                      defaultOpen={prevIsOpen !== undefined && !isMenuOpen}
                    >
                      {
                          item.children.map((subItem, ii) => {
                            return (
                              <NavLink
                                className={clsx({
                                  contactLink: true,
                                  [classes.mainActive]: getSlug(subItem.url) === getSlug(location.pathname),
                                })}
                                key={ii.toString()}
                                to={subItem.url}
                              >
                                <span className="index">{`0${index + 1}.${ii + 1}`}</span>
                                {decodeEntities(subItem.title)}
                              </NavLink>
                            )
                          })
                        }
                    </Accordion>
                  </>
                  <Else />
                  <NavLink
                    className={clsx({
                      [classes.mainActive]: getSlug(item.url) === getSlug(location.pathname),
                    })}
                    to={item.url}
                  >
                    <span className="index">{`0${index + 1}`}</span>
                    {decodeEntities(item.title)}
                  </NavLink>
                </If>
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }, [mainMenu, location])

  return (
    <div
      className={classes.root}
      ref={$root}
      role="dialog"
      aria-modal="true"
      aria-label="nav"
    >
      <div className={classes.container}>
        <div
          className={classes.wrapper}
          ref={$wrapper}
        >
          {renderLangSwitcher()}
          {/* {renderProductNav()} */}
          {renderMainNav()}
          {/* {renderExtraNav()}
          {renderInfoNav()} */}
        </div>
      </div>
    </div>
  )
}

export default memo(Nav)
