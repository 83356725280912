import axios from 'axios'
import { mainAxios, mainCacheAxios } from '@/base/axios'
import {
  REQUEST_FILTERS,
  RECEIVE_FILTERS,
  FREEZE_FILTERS,
  RESET_PRODUCTS,
  REQUEST_FILTERED_PRODUCT_LIST,
  RECEIVE_FILTERED_PRODUCT_LIST,
} from '@/actions/types'
import { API } from '@/constants'

const resetProducts = () => ({ type: RESET_PRODUCTS })

const requestFilters = () => ({ type: REQUEST_FILTERS })

const receiveFilters = (data) => ({
  type: RECEIVE_FILTERS,
  payload: data,
})

const fetchFilters = () => async (dispatch, getState) => {
  dispatch(requestFilters())
  const response = await mainCacheAxios.get(`${API.FILTERS}?lang=${getState().locale.currentLanguage}`)
  dispatch(receiveFilters(response.data[getState().locale.currentLanguage].filters))
}

const freezeFilters = (bool) => ({
  type: FREEZE_FILTERS,
  payload: bool,
})

const requestFilteredProducts = () => ({ type: REQUEST_FILTERED_PRODUCT_LIST })

const receiveFilteredProducts = (products, filters) => ({
  type: RECEIVE_FILTERED_PRODUCT_LIST,
  products,
  filters,
})

const { CancelToken } = axios
let source = CancelToken.source()

const fetchFilteredProducts = (filters, tax1, tax2) => async (dispatch, getState) => {
  // Cancel prev request if exist
  if (source !== undefined) source.cancel('Operation canceled')
  source = axios.CancelToken.source()

  dispatch(freezeFilters(true))
  dispatch(requestFilteredProducts())
  const filterConfig = getState().catalog.filters
  const currentFilters = Object.keys(filters).map((term) => ({ id: term, value: filters[term] }))
  const filterSchema = currentFilters
    .filter((t) => t.value.length > 0)
    .map((tax) => {
      const currentSchema = filterConfig.find((t) => t.id === tax.id)
      return ({
        id: tax.id,
        type: currentSchema.source.split(':')[0],
        name: currentSchema.source.split(':')[1],
        values: currentSchema.max ? { min: tax.value[0], max: tax.value[1] } : tax.value,
      })
    })
  const request = {
    filters: filterSchema,
    tax1,
    tax2,
  }
  const response = await mainAxios
    .post(
      `${API.FILTERED_PRODUCTS}?lang=${getState().locale.currentLanguage}`,
      request,
      { cancelToken: source.token },
    )
    .catch((error) => {
      return error
    })
  if (response.data) dispatch(receiveFilteredProducts(response.data.results, response.data.filters))
  dispatch(freezeFilters(false))
}

export {
  fetchFilters,
  fetchFilteredProducts,
  resetProducts,
}
