import { applyMiddleware, createStore, combineReducers, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import promise from 'redux-promise-middleware'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import reducers from '@/reducers'

const reduxDevTools = global.__REDUX_DEVTOOLS_EXTENSION__ // eslint-disable-line

export const history = createBrowserHistory()

const persistConfig = {
  key: 'local',
  storage,
  whitelist: ['user', 'regions'],
}

const reducersList = combineReducers({
  router: connectRouter(history),
  ...reducers,
})

const persistedReducer = persistReducer(persistConfig, reducersList)

const composeElements = [
  applyMiddleware(
    routerMiddleware(history),
    promise,
    thunk,
  ),
]

if (process.env.NODE_ENV !== 'production' && reduxDevTools) {
  composeElements.push(reduxDevTools())
}

export const store = createStore(persistedReducer, {}, compose(...composeElements))

export const persistor = persistStore(store)
