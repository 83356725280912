import { memo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import injectSheet from 'react-jss'
import Loader from '@/components/Loader'
import Header from '@/components/Header'
import CookieBanner from '@/components/__commons/CookieBanner'
import Overlay from '@/components/Overlay'
import Spinner from '@/components/__commons/Spinner'
// import CursorFollow from '@/components/__commons/CursorFollow'
import Nav from '@/components/Nav'
import SVGSprite from '@/components/SVGSprite'
import useFontPreload from '@/hooks/useFontPreload'
import useChangePathname from '@/hooks/useChangePathname'
import useScrollTriggerProxy from '@/hooks/useScrollTriggerProxy'
import * as contentActions from '@/actions/content'
import global from '@/styles/global'

const Layout = ({
  children,
  classes,
}) => {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isLoading, strings } = useSelector((state) => ({
    isLoading: state.loading.isLoading,
    strings: state.options.strings,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchTaxonomy = useCallback((taxonomy) => dispatch(contentActions.fetchTaxonomy(taxonomy, true)), [dispatch])

  useEffect(() => {
    // Fetch taxonomies
    fetchTaxonomy('_cta_tipology')
  }, [])

  /*------------------------------
  Precache fonts
  ------------------------------*/
  useFontPreload({ fonts: ['CutiveMono'] })

  /*------------------------------
  Init Scroll Trigger Proxy
  ------------------------------*/
  useScrollTriggerProxy()

  /*------------------------------
  Manage Change Pathname
  ------------------------------*/
  useChangePathname()

  return (
    <div className={`${classes.root} content-root`}>
      <div className="wrapper">
        {children}
      </div>
      <SVGSprite />
      <Spinner isLoading={isLoading} />
      <Nav />
      <Header />
      <Overlay />
      {/* <CursorFollow /> */}
      <Loader />
      <CookieBanner
        text={strings.cookies_short_text}
        link={strings.cookies_info_url}
        linkLabel={strings.cookies_info_label}
        acceptLabel={strings.cookies_btn_text}
      />
    </div>
  )
}

export default injectSheet(global)(memo(Layout))
