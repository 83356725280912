const style = (theme) => ({
  root: {
    '& ul': {
      display: 'flex',
      '& li': {

        paddingLeft: '10px',
        paddingRight: '10px',
        paddingBottom: '2px',
        lineHeight: '10px',
        borderRight: 'solid 1px #777777',
        transition: 'color linear 0.3s',
        textTransform: 'uppercase',
        fontSize: '12px',
        '&:last-child': {
          borderRight: 'none',
        },
        '&:hover': {
          color: theme.colors[2],
        },
        '& a': {
          position: 'relative',
        },
        '&.active': {
          pointerEvents: 'none',
          '& a:before': {
            content: '""',
            position: 'absolute',
            left: '0px',
            bottom: '-2px',
            width: '100%',
            height: '1px',
            backgroundColor: theme.colors[4],
          },
        },
      },
    },
  },
})

export default style
