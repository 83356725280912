const REMOVE_RAF_HANDLER = 'REMOVE_RAF_HANDLER'
const REMOVE_RESIZE_HANDLER = 'REMOVE_RESIZE_HANDLER'
const SAVE_RAF_HANDLER = 'SAVE_RAF_HANDLER'
const SAVE_RESIZE_HANDLER = 'SAVE_RESIZE_HANDLER'

export {
  REMOVE_RAF_HANDLER,
  REMOVE_RESIZE_HANDLER,
  SAVE_RAF_HANDLER,
  SAVE_RESIZE_HANDLER,
}
