import { useEffect, useCallback } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import usePrevious from '@/hooks/usePrevious'
import * as catalogActions from '@/actions/catalog'
import * as cursorActions from '@/actions/cursor'
import * as layoutActions from '@/actions/layout'
import * as loadingActions from '@/actions/loading'
// import * as compareActions from '@/actions/compare'
// import * as adminActions from '@/actions/admin'

const useChangePathname = () => {
  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setIsLoading = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isLoading', bool)), [dispatch])
  const setReady = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isReady', bool)), [dispatch])
  const setFirstLoad = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isFirstLoad', bool)), [dispatch])
  const setPageAnimationReady = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isPageAnimationReady', bool)), [dispatch])
  const resetProducts = useCallback(() => dispatch(catalogActions.resetProducts()), [dispatch])
  const setLayoutSubmenuVisible = useCallback((bool) => dispatch(layoutActions.setLayoutSubmenuVisible(bool)), [dispatch])
  const setCursorVisible = useCallback((bool) => dispatch(cursorActions.setCursorValue('isVisible', bool)), [dispatch])

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { pathname, isReady } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    isReady: state.loading.isReady,
  }), shallowEqual)

  /*------------------------------
  Manage Loading on change pathname
  ------------------------------*/
  const prevPathname = usePrevious(pathname)
  useEffect(() => {
    if (
      prevPathname !== undefined
      && prevPathname !== pathname
    ) {
      setPageAnimationReady(false)
      setReady(false)
      setFirstLoad(false)
      setIsLoading(true)
      setCursorVisible(false)
      setTimeout(() => {
        resetProducts()
      }, 500)
      setLayoutSubmenuVisible(false)
    }
  }, [pathname])

  /*------------------------------
  Remove Spinner
  ------------------------------*/
  useEffect(() => {
    if (isReady) setIsLoading(false)
  }, [isReady])

  return null
}

export default useChangePathname
