import { RECEIVE_REGIONS } from '@/actions/types'

const initialState = {}

function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_REGIONS:
      state = {
        ...state,
        [action.key]: action.payload,
      }
      break
    default:
      return state
  }
  return state
}

export default reducer
