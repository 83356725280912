import {
  RECEIVE_FILTERED_PRODUCT_LIST,
  RECEIVE_FILTERS,
  FREEZE_FILTERS,
  RESET_PRODUCTS,
} from '@/actions/types'

const initialState = {
  products: [],
  filteredProducts: [],
  filters: [],
  filtersLimit: [],
  isFilterLoading: false,
  totalPosts: 0,
  totalPages: 1,
  emptyResults: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_FILTERED_PRODUCT_LIST:
      state = {
        ...state,
        products: action.products,
        filtersLimit: action.filters,
        emptyResults: action.products.length === 0,
      }
      break
    case RECEIVE_FILTERS:
      state = {
        ...state,
        filters: action.payload,
      }
      break
    case FREEZE_FILTERS:
      state = {
        ...state,
        isFilterLoading: action.payload,
      }
      break
    case RESET_PRODUCTS:
      state = {
        ...state,
        products: [],
        emptyResults: false,
      }
      break
    default:
      return state
  }
  return state
}
