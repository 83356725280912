import { memo, useCallback, useEffect, useState, useRef } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import injectSheet from 'react-jss'
import clsx from 'clsx'
import gsap from 'gsap'
import * as loadingActions from '@/actions/loading'
import style from './style'
import Logo from '@/components/__ui/Logo'

const Loader = ({ classes }) => {
  const $root = useRef()
  const $progress = useRef()
  const $logo = useRef()
  const [$thumbNode, setThumbNode] = useState(null)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isSiteLoaded, isLoaderExited } = useSelector((state) => ({
    isSiteLoaded: state.loading.isSiteLoaded,
    isLoaderExited: state.loading.isLoaderExited,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setLoaderExited = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isLoaderExited', bool)), [dispatch])

  /*------------------------------
  On Complete Animation
  ------------------------------*/
  const onCompleteLoaderEnd = useCallback(() => setLoaderExited(true), [])

  /*------------------------------
  Loader End Animation
  ------------------------------*/
  const animateLoaderEnd = useCallback(() => {
    gsap.killTweensOf($thumbNode)
    gsap.timeline({ onComplete: onCompleteLoaderEnd })
      .to($thumbNode, ({
        scaleX: 1,
      }), 0.2)
      .to($thumbNode, ({
        scaleX: 0,
        transformOrigin: '50% 50%',
      }))
      .to([$progress.current, $logo.current], ({
        autoAlpha: 0,
        duration: 0.8,
      }))
      .to($root.current, ({
        autoAlpha: 0,
        duration: 0.8,
      }), '>-0.3')
  }, [$thumbNode])

  /*------------------------------
  Animate Loader End
  ------------------------------*/
  useEffect(() => {
    if (isSiteLoaded) animateLoaderEnd()
  }, [isSiteLoaded])

  useEffect(() => {
    if ($thumbNode) gsap.to($thumbNode, { scaleX: 1, duration: 20 })
  }, [$thumbNode])

  /*------------------------------
  Handle Ref onChange
  ------------------------------*/
  const onRefChange = useCallback((node) => {
    setThumbNode(node)
  }, [])

  /*------------------------------
  Render
  ------------------------------*/
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.hide]: isLoaderExited,
      })}
      ref={$root}
    >
      <div className={classes.logoWrapper} ref={$logo}>
        <Logo />
      </div>

      <div
        ref={$progress}
        className={classes.progressContainer}
      >
        <div className={classes.progressBackdrop}>
          <div ref={onRefChange} className={classes.progressThumb} />
        </div>
      </div>
    </div>
  )
}

export default injectSheet(style)(memo(Loader))
