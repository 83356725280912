const style = (theme) => {
  const output = {
    root: {
      zIndex: theme.zindex.burger,
      position: 'fixed',
      top: 0,
      width: '100%',
      // background: 'transparent',
      transition: `all 0.5s ${theme.easings.garret}`,
      pointerEvents: 'none',
    },
    rootIsMenuOpen: {},
    pointerEventsNone: {
      pointerEvents: 'none!important',
    },
    lang: {
      pointerEvents: 'all',
      display: 'flex',
      alignItems: 'center',
      transition: `opacity 0.3s ${theme.easings.garret}`,
      color: theme.colors[0],
      fontSize: 16,
    },
    wrapper: {
      padding: '60px',
      width: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'transparent',
      borderTop: 'none',
    },
    shrink: {
      padding: '30px 60px',
    },
    bgHeader: {
      height: 'auto',
      content: '""',
      display: 'block',
      width: '100%',
      backgroundColor: theme.colors[0],
      pointerEvents: 'none',
      position: 'fixed',
      left: 0,
      top: 0,
      zIndex: theme.zindex.bgHeader,
    },
    mainBarMenu: {
      pointerEvents: 'all',
      transition: `all 0.3s ${theme.easings.garret}`,
      display: 'flex',
      padding: [0, 15],
      alignSelf: 'stretch',
      position: 'relative',
    },
    logo: {
      position: 'relative',
      zIndex: theme.zindex.burger,
      cursor: 'pointer',
      pointerEvents: 'all',
      display: 'inline-block',
      '& svg': {
        fill: theme.colors[1],
      },
    },
    wrapperBurgerTitle: {
      display: 'flex',
    },
    title: {
      position: 'relative',
      zIndex: theme.zindex.burger,
      pointerEvents: 'all',
      fontSize: 20,
      color: theme.colors[1],
      fontWeight: 'bold',
      lineHeight: 1,
      marginLeft: 60,
      textTransform: 'uppercase',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
    },
    titleNavOpen: {
      color: theme.colors[0],
    },
    burger: {
      position: 'relative',
      zIndex: theme.zindex.burger,
      pointerEvents: 'all',
    },
    burgerButton: {
      cursor: 'pointer',
      backgroundColor: 'transparent',
      padding: '20px',
      margin: '-20px',
    },
    burgerIcon: {
      color: theme.colors[1],
    },
    burgerIconNavOpen: {
      color: theme.colors[0],
      '&::before': {
        color: theme.colors[0],
      },
    },
    hide: {
      opacity: 0,
      pointerEvents: 'none',
    },
    hideMobile: {},
    pageTitle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: 20,
      color: theme.colors[1],
      zIndex: theme.zindex.burger,
    },
  }

  /*------------------------------
  XXL
  ------------------------------*/
  output[theme.mq.xxl] = {
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mq.md] = {}

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mq.sm] = {
    root: {
    },
    rootIsMenuOpen: {
      // '& $mainBar': {
      //   background: 'transparent',
      //   border: '1px solid transparent',
      // },
    },
    bgHeader: {
      opacity: '1!important',
      backgroundColor: [theme.colors[0], '!important'],
    },
    wrapper: {
      padding: '30px',
    },
    title: {
      marginLeft: 30,
    },
    pageTitle: {
      display: 'none',
    },
  }

  return output
}

export default style
