import { RECEIVE_SEARCH, REQUEST_SEARCH, RESET_SEARCH } from '@/actions/types'

const initialState = {
  items: [],
  isSearching: false,
  emptyResults: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_SEARCH:
      state = {
        ...state,
        items: action.payload,
        emptyResults: action.payload.length === 0,
        isSearching: false,
      }
      break
    case REQUEST_SEARCH:
      state = {
        ...state,
        isSearching: true,
      }
      break
    case RESET_SEARCH:
      state = initialState
      break
    default:
      return state
  }
  return state
}
