export default {
  sphere: 100,
  overlayNav: 100,
  bgHeader: 102,
  mainNav: 103,
  burger: 104,
  cursor: 102,
  overlayModal: 104,
  modal: 105,
  cookiebar: 300,
  spinner: 499,
  loader: 500,
}
