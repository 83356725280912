import {
  SET_LAYOUT_STYLE,
  SET_LAYOUT_BACK_PROP,
  RESET_LAYOUT_BACK_PROP,
  SET_LAYOUT_HEADER_MINIMIZE,
  SET_LAYOUT_HEADER_LOCKED,
  SET_LAYOUT_TOP,
  SET_LAYOUT_IS_LANDING,
  SET_LAYOUT_TRANSITION,
  SET_LAYOUT_HEADER_SUBMENU_VISIBLE,
  SET_LAYOUT_NEWS_PROP,
  SET_LAYOUT_PAGE_TITLE,
} from '@/actions/types'

const initialState = {
  style: 'default',
  isTop: true,
  isLanding: false,
  backButton: {
    label: 'Back',
    visible: false,
    link: '',
    callback: false,
  },
  header: {
    minimize: false,
    locked: false,
    subMenuVisible: false,
  },
  router: {
    transition: true,
  },
  news: {
    isFromNews: false,
    offsetTop: 0,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYOUT_STYLE:
      state = {
        ...state,
        style: action.payload,
      }
      break
    case SET_LAYOUT_BACK_PROP:
      state = {
        ...state,
        backButton: {
          ...state.backButton,
          [action.key]: action.payload,
        },
      }
      break
    case RESET_LAYOUT_BACK_PROP:
      state = {
        ...state,
        backButton: initialState.backButton,
      }
      break
    case SET_LAYOUT_HEADER_MINIMIZE:
      state = {
        ...state,
        header: {
          ...state.header,
          minimize: action.payload,
        },
      }
      break
    case SET_LAYOUT_HEADER_LOCKED:
      state = {
        ...state,
        header: {
          ...state.header,
          locked: action.payload,
        },
      }
      break
    case SET_LAYOUT_HEADER_SUBMENU_VISIBLE:
      state = {
        ...state,
        header: {
          ...state.header,
          subMenuVisible: action.payload,
        },
      }
      break
    case SET_LAYOUT_TOP:
      state = {
        ...state,
        isTop: action.payload,
      }
      break
    case SET_LAYOUT_IS_LANDING:
      state = {
        ...state,
        isLanding: action.payload,
      }
      break
    case SET_LAYOUT_TRANSITION:
      state = {
        ...state,
        router: {
          transition: action.payload,
        },
      }
      break
    case SET_LAYOUT_NEWS_PROP:
      state = {
        ...state,
        news: {
          ...state.news,
          [action.key]: action.payload,
        },
      }
      break
    case SET_LAYOUT_PAGE_TITLE:
      state = {
        ...state,
        pageTitle: action.payload,
      }
      break
    default:
      return state
  }
  return state
}
