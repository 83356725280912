import { SET_BOUNDS_ELEMENT } from '@/actions/types'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BOUNDS_ELEMENT:
      state = {
        ...state,
        [action.key]: action.payload,
      }
      break
    default:
      return state
  }
  return state
}
