const style = (theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  list: {
    position: 'relative',
    width: '34px',
    height: '12px',
    '& > div': {
      height: '2px',
      left: 0,
      backgroundColor: ({ isActive }) => (isActive ? theme.colors[0] : theme.colors[1]),
      position: 'absolute',
      willChange: 'transform',
      '&.a': {
        width: '50%',
        top: 0,
      },
      '&.b': {
        width: '100%',
        top: 5,
      },
      '&.c': {
        width: '50%',
        top: 10,
        transform: 'translateX(100%)',
      },
    },

  },
})

export default style
